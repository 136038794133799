@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap");

@font-face {
  font-family: "NewYork";
  src: url("./assets/fonts/NewYork/NewYorkLarge-Semibold.ttf");
}

/*!* Bootstrap Reboot v4.6.0 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
* Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)*/
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: "Roboto Flex", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto Flex", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "NewYork", serif;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "Roboto Flex", sans-serif;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.text-monospace {
  font-family: "Roboto Flex", monospace, sans-serif !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat,
    #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
      no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nbm9uZScgc3Ryb2tlPScjZGMzNTQ1JyB2aWV3Qm94PScwIDAgMTIgMTInPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat,
    #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
      no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  -ms-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100svh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100svh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100svh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100svh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100svh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

body {
  font-family: "Roboto Flex", sans-serif;
  color: #595c72;
  line-height: 1.4;
}

@media (min-width: 992px) {
  body {
    font-size: 18px;
  }
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6,
.display-1,
.display-2 {
  font-weight: 700;
  color: #111;
}

.display-1 {
  font-size: 56px;
  line-height: 1.2;
  letter-spacing: -1.4px;
}

.display-2 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1.4px;
}

.h1 {
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -1.4px;
}

.h2 {
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: -1.4px;
}

.h3 {
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -1.4px;
}

.h4 {
  font-size: 20px;
  line-height: 1.4;
}

.h5 {
  font-size: 18px;
  line-height: 1.4;
}

.h6 {
  font-size: 16px;
  line-height: 1.4;
}

.s1 {
  font-size: 18px;
  line-height: 1.4;
}

.s2 {
  font-size: 16px;
  line-height: 1.4;
}

.p1 {
  font-size: 14px;
  line-height: 1.6;
}

.p2 {
  font-size: 12px;
  line-height: 1.6;
}

.t1 {
  font-size: 10px;
  line-height: 1.6;
}

.t2 {
  font-size: 8px;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .display-1 {
    font-weight: 800;
    font-size: 88px;
    letter-spacing: -1.4px;
  }

  .display-2 {
    font-size: 72px;
  }

  .h1 {
    font-size: 56px;
  }

  .h2 {
    font-size: 48px;
    letter-spacing: -1.4px;
  }

  .h3 {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -1.4px;
  }

  .h4 {
    font-size: 32px;
    letter-spacing: -1.4px;
  }

  .h5 {
    font-size: 24px;
  }

  .h6 {
    font-size: 20px;
    line-height: 1.6;
  }

  .s1 {
    font-size: 24px;
  }

  .s2 {
    font-size: 20px;
    line-height: 1.6;
  }

  .p1 {
    font-size: 18px;
  }

  .p2 {
    font-size: 16px;
  }

  .t1 {
    font-size: 14px;
  }

  .t2 {
    font-size: 12px;
  }
}

.title-section {
  font-weight: 600;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .title-section {
    font-weight: 700;
  }
}

.pre-title {
  font-size: 14px;
  color: #ff6600;
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .pre-title {
    font-weight: 500;
    font-size: 16px;
  }
}

.container {
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}

a {
  color: #ff6600;
  -webkit-transition: ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s;
}

.text-primary {
  color: #ff6600 !important;
}

body > img {
  bottom: 0;
}

.font-weight-semibold {
  font-weight: 600;
}

.opacity-0 {
  opacity: 0;
}

.registered-sign {
  position: relative;
}

.registered-sign {
  position: relative;
  z-index: 1;
  width: 10px;
  display: inline-block;
  height: 0;
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
  vertical-align: text-top;
}

.registered-sign:after {
  content: "®";
  display: block;
  -webkit-transform: translate(-32%, 15%);
  -ms-transform: translate(-32%, 15%);
  transform: translate(-32%, 15%);
  position: absolute;
}

.btn {
  text-transform: uppercase;
  display: inline-block;
  outline: none;
  border: 1px solid;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s;
  border-radius: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  padding: 13px 24px;
}

@media (min-width: 992px) {
  .btn {
    font-size: 16px;
    padding: 12px 24px;
    line-height: 1.38;
  }
}

.btn-lg {
  font-size: 14px;
  line-height: 1.572;
  padding: 15px 28px;
}

@media (min-width: 992px) {
  .btn-lg {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1.5;
  }
}

.btn-sm {
  font-size: 12px;
  padding: 5px 28px;
  line-height: 1.17;
}

@media (min-width: 992px) {
  .btn-sm {
    font-size: 16px;
    padding: 4px 28px;
    line-height: 1.26;
  }
}

.btn:hover {
  text-decoration: none;
}

.btn-icon {
  position: relative;
  bottom: 1px;
}

.btn-icon:first-child {
  margin-right: 4px;
}

.btn-icon:last-child {
  margin-left: 4px;
}

.btn-primary {
  color: #fff;
  background: #ff6600;
  border-color: #ff6600;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background: #cc3300;
  border-color: #cc3300;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background: #d4e2fd;
  border-color: #d4e2fd;
}

.btn-success {
  color: #fff;
  background: #5b57d5;
  border-color: #5b57d5;
}

.btn-success:hover,
.btn-success:focus {
  color: #fff;
  background: #8d6ede;
  border-color: #8d6ede;
}

.btn-success:disabled,
.btn-success.disabled {
  color: #fff;
  background: #5b57d5;
  border-color: #5b57d5;
}

.btn-light {
  color: #111;
  background: #fff;
  border-color: #fff;
}

.btn-light:hover,
.btn-light:focus {
  color: #111;
  background: #fff;
  border-color: #fff;
  opacity: 0.8;
}

.btn-default {
  color: #fff;
  background: #ff6600;
  border-color: #ff6600;
}

.btn-default:hover,
.btn-default:focus {
  color: #fff;
  background: #ff9966;
  border-color: #ff9966;
}

.btn-default:disabled,
.btn-default.disabled {
  color: #fff;
  background: #f8ceae;
  border-color: #f8ceae;
}

.btn-outline-primary {
  color: #ff6600;
  background: 0 0;
  border-color: #ff6600;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  color: #cc3300;
  background: 0 0;
  border-color: #cc3300;
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #d4e2fd;
  background: 0 0;
  border-color: #d4e2fd;
}

.btn-link {
  color: inherit;
}

.btn-link:hover {
  color: inherit;
}

.btn-link-primary {
  color: #ff6600;
}

.btn-link-primary:hover,
.btn-link-primary:focus {
  color: #1d52b9;
}

.btn-whatsapp-outline {
  color: #18be15;
  border-color: #18be15;
  background-color: transparent;
}

.btn-whatsapp-outline:hover {
  color: #fff;
  border-color: #18be15;
  background-color: #18be15;
}

.btn-wide,
.btn-wide-xs {
  display: block;
  width: 100%;
}

@media (min-width: 576px) {
  .btn-wide-xs {
    width: auto;
    display: inline-block;
  }
}

[class*="btn-outline"] {
  border: 2px solid;
}

[class*="btn-link"],
[class*="btn-link"]:hover,
[class*="btn-link"]:focus {
  text-transform: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  font-weight: 400;
}

.spin {
  -webkit-animation: spin-rotate 2s linear infinite;
  animation: spin-rotate 2s linear infinite;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 60px;
}

.spin circle {
  fill: none;
  stroke-width: 4;
}

.spin .dash {
  stroke: #0059f1;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: spin-dash 1.5s ease-in-out infinite;
  animation: spin-dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes spin-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 80, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

@keyframes spin-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 80, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

.btn .spin {
  margin-right: 8px;
  position: relative;
  top: -1px;
  width: 20px;
}

.btn .spin circle {
  stroke-width: 6;
}

.btn .spin .dash {
  stroke: #fff;
}

.transform-none {
  text-transform: none;
}

.form-group {
  margin-bottom: 16px;
  position: relative;
}

.form-group label {
  color: #777a98;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  max-width: 90%;
}

.form-control {
  border-color: #c9cad6;
  font-size: 14px;
  height: 54px;
  padding: 14px 24px;
  color: #777a98;
  font-weight: 500;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .form-control {
    height: 56px;
    font-size: 16px;
  }
}

.form-control:focus {
  border-color: #9fa6ad;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #777a98;
}

.form-control ~ label {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 15px;
  left: 0;
  -webkit-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 14px;
  line-height: 24px;
  color: #777a98;
  font-weight: 500;
  margin-left: 24px;
}

@media (min-width: 992px) {
  .form-control ~ label {
    top: 16px;
  }
}

.form-control[placeholder]:not(:-moz-placeholder-shown) ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control[placeholder]:not(:-ms-input-placeholder) ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control ~ .select2-container ~ label,
.form-control[placeholder]:focus ~ label,
.form-control[placeholder]:not(:placeholder-shown) ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control[placeholder]:not(:-ms-input-placeholder) ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control[placeholder]:-webkit-autofill ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control[placeholder]:autofill ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control[placeholder]:-webkit-autofill ~ label {
  top: 2px;
  font-size: 12px;
  opacity: 0.64;
}

.form-control ~ .select2-container ~ label {
  opacity: 0;
}

.form-control[placeholder]:not(:-moz-placeholder-shown) {
  padding-top: 22px;
  padding-bottom: 13px;
}

.form-control[placeholder]:not(:-ms-input-placeholder) {
  padding-top: 22px;
  padding-bottom: 13px;
}

.form-control[placeholder]:focus,
.form-control[placeholder]:not(:placeholder-shown) {
  padding-top: 22px;
  padding-bottom: 13px;
}

@media (min-width: 992px) {
  .form-control[placeholder]:not(:-moz-placeholder-shown) {
    padding-top: 25px;
  }

  .form-control[placeholder]:not(:-ms-input-placeholder) {
    padding-top: 25px;
  }

  .form-control[placeholder]:focus,
  .form-control[placeholder]:not(:placeholder-shown) {
    padding-top: 25px;
  }
}

.form-control[placeholder]:not(:-ms-input-placeholder) {
  padding-top: 22px;
  padding-bottom: 13px;
}

@media (min-width: 992px) {
  .form-control[placeholder]:not(:-ms-input-placeholder) {
    padding-top: 25px;
  }
}

.form-control[placeholder]:-webkit-autofill {
  padding-top: 22px;
  padding-bottom: 13px;
}

.form-control[placeholder]:autofill {
  padding-top: 22px;
  padding-bottom: 13px;
}

@media (min-width: 992px) {
  .form-control[placeholder]:-webkit-autofill {
    padding-top: 25px;
  }

  .form-control[placeholder]:autofill {
    padding-top: 25px;
  }
}

.form-control[placeholder]:-webkit-autofill {
  padding-top: 22px;
  padding-bottom: 13px;
}

@media (min-width: 992px) {
  .form-control[placeholder]:-webkit-autofill {
    padding-top: 25px;
  }
}

textarea.form-control {
  min-height: 164px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-size: 12px 8px;
  background-position: right 18px center;
}

.select2-container {
  min-width: 100%;
}

.select2-container .select2-selection {
  color: #19212d;
  border-color: #c9cad6;
  height: 54px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .select2-container .select2-selection {
    height: 56px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.select2-container.select2-container--focus .select2-selection,
.select2-container.select2-container--open .select2-selection {
  border-color: #c8cacd;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-container .select2-selection .select2-selection__rendered {
  color: #777a98;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
}

@media (min-width: 992px) {
  .select2-container .select2-selection .select2-selection__rendered {
    font-size: 16px;
  }
}

.select2-container .select2-selection .select2-selection__placeholder {
  color: #777a98;
}

.select2-container .select2-selection .select2-selection__arrow {
  background-repeat: no-repeat;
  background-size: 22px 8px;
  background-position: left center;
  height: 56px;
  width: 32px;
}

.control-search
  ~ .select2-container
  .select2-selection
  .select2-selection__arrow {
  background-size: 24px;
  padding-right: 40px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.36;
}

.has-success
  .control-search
  ~ .select2-container
  .select2-selection
  .select2-selection__arrow {
  -webkit-filter: grayscale(40%);
  filter: grayscale(40%);
}

.select2-container .select2-selection .select2-selection__arrow b {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--open .select2-dropdown--below {
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 8px 2px rgba(17, 17, 17, 0.08);
  box-shadow: 0 4px 8px 2px rgba(17, 17, 17, 0.08);
  font-size: 14px;
  line-height: 20px;
  margin-top: 9px;
  overflow: hidden;
}

.select2-container--krajee
  .select2-selection--single
  .select2-selection__arrow {
  width: 25px !important;
}

.select2-search--dropdown {
  padding: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  outline: none;
  padding: 12px 14px;
}

.select2-results__option {
  color: #616e7c;
  padding: 7px 16px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #f5f7fa;
  color: #616e7c;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f5f7fa;
  color: #616e7c;
}

.control-select2 ~ label {
  opacity: 0;
}

.control-select2:not([value=""]):valid ~ label {
  opacity: 0.64;
}

.control-select2:not([value=""]):valid
  ~ .select2-container
  .select2-selection
  .select2-selection__placeholder {
  color: #19212d;
}

.select2-container .select2-selection {
  padding-top: 12px;
  padding-bottom: 10px;
}

.control-select2:not([value=""]):valid ~ .select2-container .select2-selection {
  padding-top: 18px;
}

.control-select2.error ~ .select2-container .select2-selection__arrow {
  opacity: 0;
}

.form-group.has-error .form-control,
.form-control.error,
.form-group.has-error .select2-container .select2-selection {
  border-color: #ef5166;
  color: #ef5166;
}

.form-group.has-error
  .select2-container
  .select2-selection
  .select2-selection__rendered {
  color: #ef5166;
}

.form-group.has-success .form-control,
.form-group.has-success .select2-container .select2-selection {
  border-color: #ff6600;
  color: #ff6600;
}

.form-group.has-success
  .select2-container
  .select2-selection
  .select2-selection__rendered {
  color: #ff6600;
}

.form-group.has-error .help-block,
.form-group .error-block {
  color: #ef5166;
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
  position: static;
  margin-left: 0;
}

.form-control.error ~ .help-block {
  display: none;
}

.form-group.has-error label {
  color: #ef5166;
}

.form-group.has-success label {
  color: #ff6600;
}

.footer {
  background: #333333;
  padding: 56px 0 40px;
  color: #8a97a3;
  font-size: 14px;
}

@media (min-width: 992px) {
  .footer {
    font-size: 16px;
    padding: 72px 0 40px;
  }
}

.footer-top {
  text-align: center;
  border-bottom: 1px solid #213343;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .footer-top {
    padding-bottom: 69px;
    margin-bottom: 58px;
  }
}

.footer-top-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -1.4px;
  color: #fff;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .footer-top-title {
    font-size: 48px;
    line-height: 1.2;
    letter-spacing: -1.4px;
    margin-bottom: 24px;
  }

  .footer-top-title:not(:first-child) {
    margin-top: 24px;
  }
}

.footer-top-buttons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  max-width: 532px;
  margin: auto;
}

@media (min-width: 992px) {
  .footer-top-buttons {
    grid-template-columns: 1fr 1fr;
  }
}

.footer-top-buttons > * {
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
}

.footer-cta {
  background-color: #0db10d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: left;
  gap: 8px;
  padding: 0;
  border-radius: 15px;
  color: #fff;
}

.footer-cta:hover,
.footer-cta:active {
  color: #fff;
  background-color: #10d810;
}

.footer-cta span {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  letter-spacing: -0.5px;
}

@media (min-width: 992px) {
  .footer-cta span {
    font-size: 16px;
  }
}

.footer-copy {
  margin-top: 56px;
  font-size: 12px;
  line-height: 1.4;
}

.footer-partner-title {
  font-weight: 400;
  font-size: 14px;
  color: #8a97a3;
  margin-bottom: 18px;
}

.footer-partner-logo {
  width: auto;
}

@media (min-width: 992px) {
  .footer-partner-logo {
    height: 28px;
  }
}

.accordion .accordion {
  margin-top: 14px;
  border-top: 1px solid #d6d8e8;
}

.accordion-item {
  border-bottom: 1px solid #d6d8e8;
}

.accordion-item-header {
  cursor: pointer;
  position: relative;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding: 24px 32px 24px 0;
}

@media (min-width: 992px) {
  .accordion-item-header {
    padding: 32px 32px 32px 0;
  }
}

.accordion-item-header.collapsed {
}

.accordion-item-title {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.4;
  color: #595c72;
  font-weight: 500;
}

@media (min-width: 992px) {
  .accordion-item-title {
    font-size: 18px;
  }
}

.accordion-item-body {
  color: #303743;
}

.accordion-item-body :last-child {
  margin-bottom: 0;
}

.accordion-item-body .accordion-item-text {
  opacity: 1;
  -webkit-transition: ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s;
}

.accordion-item-body.collapsing .accordion-item-text {
  opacity: 0;
}

.accordion-item-text {
  padding-bottom: 16px;
  position: relative;
  bottom: 8px;
  font-size: 14px;
  line-height: 1.6;
  color: #777a98;
}

@media (min-width: 992px) {
  .accordion-item-text {
    font-size: 16px;
  }
}

.accordion-item-main-header {
  cursor: pointer;
  position: relative;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding: 12px 32px 12px 0;
}

@media (min-width: 992px) {
  .accordion-item-main-header {
    padding: 28px 32px 28px 0;
  }
}

.accordion-item-main-header.collapsed {
}

.accordion-item-main-header .accordion-item-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  color: #111;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .accordion-item-main-header .accordion-item-title {
    font-size: 24px;
    line-height: 1.2;
  }
}

.slick-custom {
  padding-bottom: 64px;
}

.slick-custom .slick-arrow {
  font-size: 0;
  line-height: 0;
  -webkit-transition: all 0.32s;
  -o-transition: all 0.32s;
  transition: all 0.32s;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-color: #d0d1dd;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  z-index: 99;
  position: absolute;
  bottom: 0;
}

.slick-custom .slick-arrow:active {
  opacity: 0.72;
}

@media (min-width: 992px) {
  .slick-custom .slick-arrow {
    width: 48px;
    height: 48px;
    background-size: 18px 18px;
  }

  .slick-custom .slick-arrow:not(.slick-disabled) {
    background-color: #ff6600;
  }
}

.slick-custom .slick-prev {
  left: calc(50% - 28px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slick-custom .slick-next {
  right: calc(50% - 28px);
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

@media (min-width: 992px) {
  .slick-custom .slick-prev {
    left: calc(50% - 32px);
  }

  .slick-custom .slick-next {
    right: calc(50% - 32px);
  }
}

.numbers {
  background: #333333;
  padding-top: 56px;
  padding-bottom: 56px;
  color: #fff;
}

@media (min-width: 992px) {
  .numbers {
    padding-top: 120px;
    padding-bottom: 112px;
  }
}

.numbers-heading {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .numbers-heading {
    margin-bottom: 80px;
    font-size: 20px;
    line-height: 1.6;
  }
}

.numbers-heading > :last-child {
  margin-bottom: 0;
}

.numbers-title {
  color: #fff;
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .numbers-title {
    font-size: 48px;
    margin-bottom: 24px;
  }
}

.numbers-item {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .numbers-item {
    margin-bottom: 80px;
    position: relative;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
  }

  .numbers-item + .numbers-item:before {
    display: block;
    content: "";
    height: 100px;
    width: 1px;
    background: #193148;
    position: absolute;
    left: -72px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .numbers-item:last-child {
    margin-right: 6%;
  }
}

.numbers-item > :first-child {
  font-size: 56px;
  line-height: 1.2;
  letter-spacing: -1.4px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .numbers-item > :first-child {
    font-size: 88px;
  }
}

@media (min-width: 992px) {
  .numbers-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.numbers-secondary .numbers-item > :first-child {
  color: #111;
}

@media (min-width: 992px) {
  .numbers-secondary .numbers-item > :first-child {
    font-size: 72px;
  }
}

.numbers-secondary .numbers-item + .numbers-item::before {
  background-color: #e4e5ee;
}

.registry-seal {
  background: #fff;
  border-radius: 50%;
  color: #111;
  padding: 12px;
}

.registry-seal:before {
  content: "";
  display: block;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  color: #ff6600;
  top: 50%;
  left: 50%;
  width: 35px;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.registry-seal svg {
  -webkit-animation: spin-rotate 8s linear infinite;
  animation: spin-rotate 8s linear infinite;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.modal-title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
}

@media (min-width: 992px) {
  .modal-title {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -1.4px;
  }
}

.modal-close {
  color: #111;
  background: 0 0;
  border: 0;
  padding: 0;
  margin-left: auto;
  line-height: 1;
}

.modal-header {
  border: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-body {
  padding: 32px 24px;
}

.modal-header + .modal-body {
  padding-top: 4px;
}

.modal-content {
  border-radius: 20px;
}

.modal-dialog {
  margin: 20px;
  max-width: 500px;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .modal-form .modal-body {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.hero {
  background: #ff6600;
  padding-top: 44px;
  padding-bottom: 58px;
  color: #fff;
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.hero.light-orange-bg {
  background: #ff9966;
}

@media (min-width: 992px) {
  .hero {
    padding-top: 45px;
    padding-bottom: 55px;
    position: relative;
    z-index: 0;
  }
}

.hero .container {
  position: relative;
  z-index: 1;
}

.hero-title {
  color: #fff;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .hero-title {
    margin-bottom: 30px;
  }
}

.hero-opening {
  color: #fdfaf7;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .hero-opening {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
  }
}

.valor {
  text-decoration: underline;
}

.hero-brand-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 24px;
}

@media (max-width: 991px) {
  .hero-brand {
    width: 270px !important;
  }
}

@media (min-width: 992px) {
  .hero-brand-cta {
    margin-bottom: 35px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.hero-cta {
  color: #fff;
  background-color: #0db10d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  padding: 12px 28px;
  border-radius: 100px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.hero-cta:hover,
.hero-cta:active {
  background-color: #10d810;
}

.hero-cta-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.hero-cta-text > span:first-child {
  font-size: 12px;
  color: #d4ffd4;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.hero-cta-text > span:last-child {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.hero-cta-container {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
}

.hero-cta-container > a {
  width: 100%;
}

@media (min-width: 992px) {
  .hero-cta-container {
    margin: 0;
    max-width: 300px;
    margin-right: 77px;
  }
}

.hero-underline {
  position: relative;
}

.hero-underline span {
  position: relative;
  z-index: 1;
}

.hero-underline:before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 202px;
  height: 16px;
  z-index: -1;
}

@media (min-width: 992px) {
  .hero-underline:before {
    width: 368px;
    height: 31px;
    right: 0;
    bottom: -13px;
  }
}

.hero-bg {
  position: absolute;
  left: 0;
  top: 22%;
  opacity: 0.4;
}

.hero-bg img {
  height: auto;
  width: 100%;
}

@media (min-width: 992px) {
  .hero-bg {
    right: 0;
    left: auto;
    top: 0;
    max-width: 697px;
  }
}

.hero .registry-seal {
  right: -18px;
  position: absolute;
  bottom: 86px;
  display: none;
}

@media (min-width: 1200px) {
  .hero .registry-seal {
    display: block;
  }
}

.hero .btn .registered-sign {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.hero .btn .registered-sign:after {
  -webkit-transform: translate(-6%, -47%) scale(0.7);
  -ms-transform: translate(-6%, -47%) scale(0.7);
  transform: translate(-6%, -47%) scale(0.7);
}

.box-form {
  position: relative;
}

.box-form-title {
  margin-bottom: 16px;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .box-form-title {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
  }
}

.box-form-title .registered-sign {
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
}

.box-form-title .registered-sign:after {
  -webkit-transform: translate(-32%, 3%);
  -ms-transform: translate(-32%, 3%);
  transform: translate(-32%, 3%);
}

.form.shadow .box-form .box-form-body {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.box-form-body {
  position: relative;
  z-index: 9;
  background: #fff;
  border-radius: 30px;
  padding: 25px;
  max-width: 441px;
  margin: 0 auto;
}

.box-form-body.partnership {
  border-radius: 0px 0px 30px 30px;
}

@media (min-width: 992px) {
  .box-form-body {
    padding: 35px 48px 38px;
  }
  .box-form-body.partnership {
    padding: 25px 48px 38px;
  }
}

.form-steps {
  counter-reset: section;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media (min-width: 992px) {
  .form-steps {
    margin-bottom: 24px;
    padding-left: 0;
    padding-right: 0;
  }
}

.form-steps:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% - 32px);
  border-bottom: 1px dashed #e4e4ea;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.form-steps span {
  position: relative;
  z-index: 1;
  background: #ff6600;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-weight: 600;
  font-size: 12px;
  -webkit-box-shadow: 0 0 0 10px #fff;
  box-shadow: 0 0 0 10px #fff;
}

@media (min-width: 992px) {
  .form-steps span {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
  }
}

.form-steps span:before {
  counter-increment: section;
  content: counter(section);
}

.form-steps span.active ~ span {
  background: #e4e4ea;
  color: #adafc1;
}

.form-safe {
  margin-top: 16px;
  text-align: center;
}

@media (min-width: 992px) {
  .form-safe {
    margin-top: 14px;
  }
}

.form-safe img {
  width: auto;
}

.form-avatar {
  margin-top: 24px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
}

@media (min-width: 992px) {
  .form-avatar {
    text-align: left;
    font-size: 16px;
    line-height: 1.4;
  }
}

.form-avatar img {
  height: auto;
  max-width: 100%;
  display: block;
  margin: 0 auto 16px;
}

@media (min-width: 992px) {
  .form-avatar img {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .form-avatar img {
    width: 257px;
  }
}

.form-avatar p:last-child {
  margin-bottom: 0;
}

.section-opening {
  margin-top: 56px;
  margin-bottom: 40px;
  text-align: center;
}

@media (min-width: 992px) {
  .section-opening {
    margin-top: 120px;
    margin-bottom: 64px;
  }
}

.section-opening .p1 {
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .section-opening .p1 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
  }
}

.section-opening-title {
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .section-opening-title {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  .section-opening-title .registered-sign {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }

  .section-opening-title .registered-sign:after {
    -webkit-transform: translate(-32%, -6%);
    -ms-transform: translate(-32%, -6%);
    transform: translate(-32%, -6%);
  }
}

.section-opening p:last-child {
  margin-bottom: 0;
}

.section {
  margin-top: 40px;
  margin-bottom: 64px;
  text-align: center;
}

@media (min-width: 992px) {
  .section {
    text-align: inherit;
    margin-top: 64px;
    margin-bottom: 72px;
  }
}

.section p:last-child {
  margin-bottom: 0;
}

.section-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 100%;
  margin-bottom: 24px;
  border-radius: 25px;
}

@media (min-width: 992px) {
  .section-img {
    margin-bottom: 0;
    width: auto;
    border-radius: 30px;
  }
}

.section-title {
  margin-bottom: 8px;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .section-title {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 1.2;
  }
}

.box-fb {
  padding-top: 40px;
  padding-bottom: 56px;
}

@media (min-width: 992px) {
  .box-fb {
    padding: 0;
    margin-bottom: 120px;
    margin-top: 120px;
  }
}

@media (min-width: 992px) {
  .box-fb-bg {
    padding: 50px 65px 50px 79px;
    background: #edf2f7;
    border-radius: 20px;
  }
}

.box-fb-title {
  margin-bottom: 24px;
  line-height: 1.2;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .box-fb-title {
    margin-top: 42px;
    margin-bottom: 48px;
    font-size: 48px;
  }
}

.box-fb-rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.box-fb-rate + .box-fb-rate {
  margin-top: 8px;
}

.box-fb-logo {
  margin-right: 8px;
}

.box-fb-star {
  margin-right: 4px;
  color: #fed530;
}

.box-fb-numbers {
  font-size: 14px;
  color: #1d52b9;
}

.box-fb-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  align-items: center;
}

@media (min-width: 768px) {
  .box-fb-comments {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: inherit;
  }
}

@media (min-width: 992px) {
  .box-fb-comments {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 0;
  }
}

.box-fb-comments img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  max-width: 355px;
}

@media (min-width: 576px) {
  .box-fb-comments img {
    max-width: 380px;
  }
}

@media (min-width: 1200px) {
  .box-fb-comments img {
    border-radius: 20px;
    max-width: none;
    width: 490px;
  }
}

.section-app {
  background-color: #ff6600;
  overflow: hidden;
  text-align: center;
  padding-top: 56px;
}

@media (min-width: 992px) {
  .section-app {
    color: #eaf1ff;
    min-height: 640px;
    padding-top: 120px;
    padding-bottom: 0px;
    text-align: inherit;
    position: relative;
  }
}

@media (min-width: 992px) {
  .section-app .container {
    position: relative;
    z-index: 2;
  }
}

.section-app-img {
  margin-left: -20px;
  margin-right: -20px;
  background: #ff6600;
  border-radius: 25px;
}

@media (min-width: 992px) {
  .section-app-img {
    background: 0 0;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .section-app-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: -49px;
    position: relative;
    left: -22%;
  }
}

@media (min-width: 1200px) {
  .section-app-img {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    left: 65px;
  }
}

.section-app-img img {
  height: auto;
  width: 100%;
  max-width: 540px;
}

@media (min-width: 992px) {
  .section-app-img img {
    width: 868px;
    max-width: none;
  }
}

.section-app-content {
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .section-app-content {
    margin-top: 100px;
  }
}

.section-app-title {
  margin-bottom: 8px;
  line-height: 1.2;
  letter-spacing: -1.4px;
  color: #fff;
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .section-app-title {
    font-size: 48px;
    margin-bottom: 24px;
  }
}

@media (min-width: 992px) {
  .section-app .p2 {
    font-size: 20px;
    line-height: 1.6;
  }
}

.section-app-download {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 992px) {
  .section-app-download {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.section-app-download div + div {
  margin-top: 8px;
  display: inline-block;
}

@media (min-width: 992px) {
  .section-app-download div + div {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 16px;
  }
}

.section-app-qr {
  margin-top: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #c5eaf7;
  display: none;
}

@media (min-width: 992px) {
  .section-app-qr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.section-app-qr img {
  margin-right: 24px;
}

@media (min-width: 992px) {
  .section-app-qr .p2 {
    font-size: 16px;
    line-height: 1.4;
  }
}

.section-app-qr p:last-child {
  margin-bottom: 0;
}

.section-app-bg {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.4;
}

.section-plans {
  background-color: #ff6600;
  color: #eaf1ff;
  overflow: hidden;
  text-align: center;
  padding: 56px 0;
  position: relative;
}

@media (min-width: 992px) {
  .section-plans {
    padding: 120px 0;
    min-height: 640px;
  }
}

@media (min-width: 992px) {
  .section-plans .container {
    position: relative;
    z-index: 2;
  }
}

.section-plans-title {
  margin-bottom: 24px;
  line-height: 1.2;
  letter-spacing: -1.4px;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 18px;
  font-size: 24px;
}

@media (min-width: 500px) {
  .section-plans-title {
    font-size: 27px;
  }
}

@media (min-width: 600px) {
  .section-plans-title {
    font-size: 30px;
    margin-bottom: 32px;
  }
}

@media (min-width: 750px) {
  .section-plans-title {
    font-size: 33px;
  }
}

@media (min-width: 1080px) {
  .section-plans-title {
    font-size: 43px;
    max-width: 800px;
    margin: 0 auto 32px;
  }
}

.section-plans-bg {
  position: absolute;
  top: 50%;
  right: -86px;
  transform: translateY(-50%);
  opacity: 0.6;
}

@media (min-width: 600px) {
  .section-plans-bg {
    left: 0;
    bottom: 0;
    right: inherit;
    transform: inherit;
    top: inherit;
  }
}

.section-plans-cards {
  padding: 0px 2rem;
  z-index: 1;
  position: relative;
  overflow-x: auto;
}

@media (min-width: 1346px) {
  .section-plans-cards {
    justify-content: center;
  }
}

.section-plans-card {
  border-radius: 33px;
  padding: 25px;
  width: 290px;
  margin: 0px 1.2rem 1rem;
  flex-shrink: 0;
}

.section-plans-card .title {
  font-size: 23px;
  color: #3c3c3c;
}

.section-plans-card .subtitle {
  font-size: 11px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 0.3rem;
  color: #626262;
}

.section-plans-card .price {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: #3c3c3c;
}

.section-plans-card .price s {
  color: #cccccc;
}

.section-plans-card .price-description {
  font-size: 12px;
  margin-bottom: 0.7rem;
  color: #3c3c3c;
}

.section-plans-card .divider {
  width: 100%;
  height: 2.5px;
  background: #aaaaaa;
  margin-bottom: 0.7rem;
}

.section-plans-card .desc {
  font-size: 10px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  color: #626262;
}

.section-plans-card .list {
  width: 100%;
  margin-bottom: 0.7rem;
}

.section-plans-card .list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.section-plans-card .list-item p {
  font-size: 9px;
  margin-left: 8px;
  margin-bottom: 0px;
  color: #3c3c3c;
}

.brands {
  background: #f8f9ff;
  padding-top: 56px;
  padding-bottom: 64px;
  text-align: center;
  overflow: hidden;
}

@media (min-width: 992px) {
  .brands {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.brands-opening {
  margin-bottom: 42px;
}

@media (min-width: 992px) {
  .brands-opening {
    margin-bottom: 80px;
  }
}

.brands-closing {
  margin-top: 42px;
}

@media (min-width: 992px) {
  .brands-closing {
    margin-top: 80px;
  }
}

.brands-item {
  background: #fff;
  border: 1px solid #cfd4dd;
  border-radius: 20px;
  padding: 30px;
  height: 120px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .brands-item {
    padding: 55px;
    height: 180px;
  }
}

.brands-item img {
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  height: 100%;
  width: 100%;
  filter: grayscale(100%);
}

.brands-title {
  line-height: 1.2;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .brands-title {
    font-size: 48px;
    margin-bottom: 16px;
  }
}

.brands .btn {
  margin-top: 24px;
}

@media (min-width: 992px) {
  .brands .btn {
    margin-top: 0;
  }
}

.brands-slider-group {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .brands-slider {
    padding-bottom: 0;
  }
}

.brands-slider:not(.slick-initialized) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.brands-slider:not(.slick-initialized) .brands-item {
  margin: 0 8px;
  width: 50%;
}

@media (min-width: 992px) {
  .brands-slider:not(.slick-initialized) .brands-item {
    width: 275px;
    height: 193px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 275px;
    flex: 0 0 275px;
  }

  .brands-slider:not(.slick-initialized) .brands-item:last-child {
    margin-right: 0;
  }
}

.brands-slider:not(.slick-initialized) .brands-item:nth-child(2) ~ div {
  display: none !important;
}

@media (min-width: 992px) {
  .brands-slider:not(.slick-initialized) .brands-item:nth-child(2) ~ div {
    display: block !important;
  }
}

.brands-slider-group + .brands-slider-group {
  display: block;
  margin-top: 16px;
}

@media (min-width: 992px) {
  .brands-slider-group .brands-slider + .brands-slider {
    margin-top: -192px;
  }
}

.brands .slick-list {
  margin: 0 -8px !important;
  padding-bottom: 0.2rem;
}

.brands .slick-slide > div {
  margin: 0 8px !important;
}

.social-proofs .slick-slide > div {
  margin: 0 12px !important;
}

.drive-thru {
  background: #cc3300;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  margin-top: 56px;
  margin-bottom: 56px;
  position: relative;
  overflow: hidden;
  min-height: 408px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .drive-thru {
    text-align: inherit;
    overflow: hidden;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.6;
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.drive-thru-body {
  padding: 40px 20px 52px;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .drive-thru-body {
    padding: 82px 300px 82px 95px;
  }
}

.drive-thru-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
}

@media (min-width: 992px) {
  .drive-thru-bg {
    width: 434px;
    left: auto;
    right: -2px;
    opacity: 0.4;
  }
}

.drive-thru-bg img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.drive-thru-title {
  color: #fff;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .drive-thru-title {
    margin-bottom: 32px;
    font-size: 41px;
  }
}
@media (min-width: 1200px) {
  .drive-thru-title {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .drive-thru-title .registered-sign {
    -webkit-transform: scale(0.74);
    -ms-transform: scale(0.74);
    transform: scale(0.74);
  }

  .drive-thru-title .registered-sign:after {
    -webkit-transform: translate(-50%, -3%);
    -ms-transform: translate(-50%, -3%);
    transform: translate(-50%, -3%);
  }
}

.drive-thru .highlight {
  color: #ff9966;
}

.drive-thru .btn-link {
  color: #ff9966;
}

.drive-thru .btn-link:hover {
  color: #ff6600;
}

.operation {
  margin-top: 56px;
  margin-bottom: 56px;
  text-align: center;
}

@media (min-width: 992px) {
  .operation {
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: inherit;
  }
}

.operation .pre-title {
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .operation .pre-title {
    margin-bottom: 24px;
  }
}

.operation-title {
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .operation-title {
    margin-bottom: 72px;
    font-size: 48px;
  }
}

.operation-img {
  display: block;
  margin: 0 auto 24px;
}

@media (min-width: 992px) {
  .operation-img {
    margin: 0 0 36px;
    width: 87px;
    height: auto;
  }
}

.operation-cta {
  margin-top: 40px;
}

@media (min-width: 992px) {
  .operation-cta {
    margin-top: 80px;
  }
}

.operation-item > :last-child {
  margin-bottom: 0;
}

.operation-item + .operation-item {
  margin-top: 40px;
}

@media (min-width: 992px) {
  .operation-item + .operation-item {
    margin-top: 0;
  }
}

.operation-item-title {
  font-weight: 600;
  letter-spacing: -1.4px;
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .operation-item-title {
    font-size: 24px;
    margin-bottom: 12px;
    letter-spacing: normal;
  }
}

.media-group {
  overflow: hidden;
  background: #f8f9ff;
  padding-top: 56px;
  padding-bottom: 56px;
}

@media (min-width: 992px) {
  .media-group {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .media-group-title {
    margin-bottom: 16px;
    font-size: 48px;
  }

  .media-group-title + p {
    font-size: 20px;
    line-height: 1.6;
  }
}

.media-group-heading {
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .media-group-heading {
    margin-bottom: 56px;
  }
}

.media-group-heading > :last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .media-group .container {
    overflow: visible;
  }
}

.media-slider-item {
  width: 100%;
}

@media (min-width: 992px) {
  .media-slider-item {
    max-width: 752px;
  }
}

.media-slider:not(.slick-initialized) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.media-slider:not(.slick-initialized) .media-slider-item {
  margin-left: 15px;
  margin-right: 15px;
}

.media-slider:not(.slick-initialized) .media-slider-item:first-child ~ * {
  display: none;
}

@media (min-width: 992px) {
  .media-slider:not(.slick-initialized) .media-slider-item:first-child ~ * {
    display: block;
  }
}

@media (min-width: 992px) {
  .media-slider:not(.slick-initialized) .media-video {
    width: 752px;
  }
}

@media (min-width: 992px) {
  .media-slider:not(.slick-initialized) .media-new {
    width: 397px;
  }
}

@media (min-width: 992px) {
  .media-slider .slick-list {
    margin-left: -15px;
    margin-right: -15px;
    overflow: visible;
  }

  .media-slider .slick-list .slick-slide {
    margin-left: 15px;
    margin-right: 15px;
  }

  .media-slider .slick-list .media-new {
    width: 397px;
  }
}

@media (min-width: 992px) {
  .media-slider .slick-arrow {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    top: -92px;
  }

  .media-slider .slick-prev {
    right: 64px;
    left: auto;
  }

  .media-slider .slick-next {
    right: 0;
  }
}

.media-new {
  height: 400px;
  background: #fff;
  border: 1px solid #eaf1ff;
  border-radius: 8px;
  display: block;
  color: #adafc1;
}

@media (min-width: 992px) {
  .media-new {
    height: 466px;
  }
}

.media-new-body {
  padding: 24px 48px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.media-new-logo {
  margin-bottom: 48px;
}

.media-new-logo img {
  width: auto;
}

.media-new-date {
  color: #adafc1;
  margin-bottom: 24px;
  font-size: 14px;
}

.media-new-title {
  font-size: 20px;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .media-new-title {
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -1.4px;
  }
}

.media-new .btn {
  margin-top: auto;
  text-align: left;
}

.media-video {
  display: block;
  height: 400px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

@media (min-width: 992px) {
  .media-video {
    height: 466px;
  }
}

.media-video,
.media-video:hover {
  color: #fff;
}

@media (min-width: 992px) {
  .media-video:hover .media-video-play {
    width: 86px;
    height: 86px;
  }
}

.media-video-body {
  position: relative;
  z-index: 1;
  padding: 40px 40px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

@media (min-width: 992px) {
  .media-video-body {
    padding: 40px 112px 30px 40px;
  }
}

.media-video-body > :last-child {
  margin-bottom: 0;
}

.media-video-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.media-video-bg img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.media-video-logo img {
  width: auto;
}

@media (min-width: 992px) {
  .media-video-logo img {
    height: 57px;
  }
}

.media-video-play {
  position: absolute;
  left: 50%;
  top: calc(50% - 28px);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: auto;
  -webkit-transition: ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s;
}

@media (min-width: 992px) {
  .media-video-play {
    width: 80px;
    height: 80px;
  }
}

.media-video-date {
  margin-top: auto;
  margin-bottom: 16px;
  font-size: 14px;
}

@media (min-width: 992px) {
  .media-video-date {
    font-size: 16px;
    font-weight: 500;
  }
}

.media-video-title {
  font-size: 20px;
  line-height: 1.4;
  color: #fff;
}

@media (min-width: 992px) {
  .media-video-title {
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -1.4px;
  }
}

.post {
  font-size: 16px;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .post {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.post,
.post:hover {
  color: #595c72;
}

.post-group {
  margin-top: 56px;
  margin-bottom: 56px;
}

@media (min-width: 992px) {
  .post-group {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.post-group-heading {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .post-group-heading {
    margin-bottom: 120px;
    font-size: 20px;
    line-height: 1.6;
  }
}

.post-group-heading > :last-child {
  margin-bottom: 0;
}

.post-group-title {
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .post-group-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 24px;
  }
}

.post-group-cta {
  text-align: center;
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .post-group-cta {
    margin-top: 120px;
  }
}

.post-date {
  font-size: 14px;
  color: #777a98;
  line-height: 1;
}

@media (min-width: 992px) {
  .post-date {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 16px;
  }
}

.post-title {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 16px;
  letter-spacing: -1.4px;
}

@media (min-width: 992px) {
  .post-title {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 24px;
  }
}

.post-img {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 16px;
}

@media (min-width: 991px) {
  .post-img {
    width: 100%;
    height: 274px;
    margin-right: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 264px;
    flex: 0 0 264px;
  }
}

@media (min-width: 1200px) {
  .post-img {
    margin-bottom: 0;
    width: 264px;
  }
}

.post-img img {
  width: 100%;
  height: auto;
}

@media (min-width: 991px) {
  .post-img img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .post-slider:not(.slick-initialized) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -16px;
  }
}

@media (min-width: 992px) {
  .post-slider:not(.slick-initialized) .post-slider-item {
    margin: 0 16px;
    width: 50%;
  }
}

.post-slider:not(.slick-initialized) .post-slider-item:nth-child(1) ~ div {
  display: none;
}

@media (min-width: 992px) {
  .post-slider:not(.slick-initialized) .post-slider-item:nth-child(1) ~ div {
    display: block;
  }
}

@media (min-width: 992px) {
  .post-slider:not(.slick-initialized) .post-slider-item:nth-child(2) ~ div {
    display: none;
  }
}

@media (min-width: 992px) {
  .post-slider {
    padding-bottom: 0;
  }

  .post-slider .slick-arrow {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    top: -168px;
  }

  .post-slider .slick-prev {
    right: 64px;
    left: auto;
  }

  .post-slider .slick-next {
    right: 0;
  }

  .post-slider .slick-list {
    margin: 0 -16px !important;
  }

  .post-slider .slick-slide > div {
    padding: 0 16px !important;
  }
}

.section-video {
  background-color: #333333;
  color: #fff;
  text-align: center;
  height: 400px;
}

.section-video .container {
  padding-top: 56px;
  border-top: 1px solid #193148;
}

.section-video .h2 {
  color: #fff;
}

.section-video p {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
}

.section-video p span {
  font-weight: 600;
}

@media (min-width: 992px) {
  .section-video {
    height: 540px;
  }

  .section-video .container {
    padding-top: 130px;
  }

  .section-video .h2 {
    margin-bottom: 16px;
  }

  .section-video p {
    font-size: 20px;
    line-height: 1.6;
  }
}

@media (min-width: 1200px) {
  .section-video {
    height: 614px;
  }
}

.section-video div.video-wrapper {
  width: 100%;
  border-radius: 16px;
  background-color: #f9f9f9;
  padding: 1rem;
  -webkit-transform: translateY(14%);
  -ms-transform: translateY(14%);
  transform: translateY(14%);
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
}

@media (min-width: 992px) {
  .section-video div.video-wrapper {
    -webkit-transform: translateY(10%);
    -ms-transform: translateY(10%);
    transform: translateY(10%);
    padding: 1rem 3rem;
    height: 400px;
  }
}

@media (min-width: 1200px) {
  .section-video div.video-wrapper {
    height: 500px;
  }
}

.faq-home {
  background: #f8f9ff;
  padding: 282px 0 56px;
}

.faq-home.concat-faqs {
  padding: 28px 0 56px;
}

@media (min-width: 1200px) {
  .faq-home {
    padding: 317px 0 120px;
  }
  .faq-home.concat-faqs {
    padding: 60px 0 120px;
  }
}

.faq-home-title {
  margin-bottom: 24px;
  line-height: 1.2;
  font-weight: 600;
}

@media (min-width: 992px) {
  .faq-home-title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: 700;
    margin-top: 30px;
  }
}

.payment-section {
  padding: 32px 0px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.2;
}

.payment-obs {
  font-size: 12px;
}

@media (min-width: 992px) {
  .payment-section {
    padding: 40px 0px;
  }
}

.payment-text {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .payment-text {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }
}

.payment-item {
  min-width: 120px;
  height: 21px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.42;
}

.payment-item:hover {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

@media (min-width: 992px) {
  .payment-item {
    min-width: 144px;
  }
}

.payment-item img {
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.payment-slider:not(.slick-initialized) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-modal .close-modal {
  background-color: transparent;
  border: none;
  outline: none;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 20px;
  top: 24px;
  z-index: 12;
  pointer-events: auto;
}

.form-modal .close-modal span:nth-child(1),
.form-modal .close-modal span:nth-child(2) {
  display: block;
  width: 20px;
  height: 3px;
  background-color: #000;
  border-radius: 10px;
  position: absolute;
}

.form-modal .close-modal span:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.form-modal .close-modal span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-modal .box-form.steps-bg::before,
.form-modal .box-form.steps-bg::after {
  content: none;
}

.form-modal .box-form-body {
  max-width: 410px;
  margin: auto;
}

.form-modal .modal-content {
  border-radius: 0;
  border: none;
}

.form-modal .modal-content .box-form-body {
  border: none;
}

@media (max-width: 991px) {
  .form-modal .modal-dialog {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
  }

  .form-modal.animation .modal-dialog {
    -webkit-animation: animation 0.3s ease-in-out;
    animation: animation 0.3s ease-in-out;
  }

  @-webkit-keyframes animation {
    0% {
      -webkit-transform: translateY(70%);
      transform: translateY(70%);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes animation {
    0% {
      -webkit-transform: translateY(70%);
      transform: translateY(70%);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .form-modal .modal-dialog {
    left: calc(50vw - 250px);
  }
}

@media (min-width: 992px) {
  .form-modal .modal-content {
    border-radius: 30px;
  }

  .form-modal .modal-dialog {
    margin-top: 100px;
  }

  .form-modal .box-form-title {
    text-align: center;
  }

  .form-modal .box-form-body {
    padding: 45px 20px 50px;
  }

  .form-modal .close-modal {
    right: 24px;
  }
}

.wpp-float {
  position: fixed;
  z-index: 100;
  top: 83vh;
  left: 31px;
}

.wpp-float a .fab {
  background-color: #32d951;
  width: 80px;
  height: 80px;
}

.wpp-float a .fab svg {
  font-size: 3rem;
}

.wpp-float a:hover .fab,
.wpp-float a:active .fab {
  background-color: #10d810;
}

@media (max-width: 991px) {
  .wpp-float {
    top: 88vh;
    left: 21px;
  }

  .wpp-float a .fab {
    width: 60px;
    height: 60px;
  }

  .wpp-float a .fab svg {
    font-size: 2rem;
  }
}
