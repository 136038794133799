code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  flex: 1;
}

body {
  background-color: #fdfaf7 !important;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  height: 100%;
}

.root-with-padding {
  padding-top: 73px;
}

.zIndexUp {
  z-index: 2147483644 !important;
}

.default-icon-button.MuiIconButton-root:hover,
.default-button.MuiButton-root:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.default-text-field .MuiInputLabel-root,
.default-text-field .MuiInputLabel-root.Mui-focused {
  color: #fff;
}

.default-text-field .MuiInput-root .MuiInput-input {
  padding-left: 0.5rem;
}

/* SCROLL */

.scroll::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.scroll::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.scroll::-webkit-scrollbar-track {
  background: transparent;
}

@media (max-height: 669.95px) and (max-width: 799.95px) {
  .grid-title {
    min-height: 220px !important;
  }

  .img-lawyers {
    width: 130vw !important;
  }

  .circle-lawyers {
    bottom: -500px !important;
    right: -460px !important;
    width: 1000px !important;
    height: 1000px !important;
  }
}

@media (max-height: 480px) and (max-width: 320px) {
  .circle-lawyers {
    bottom: -350px !important;
    right: -320px !important;
    width: 750px !important;
    height: 750px !important;
  }
}

.rounded-card {
  border-radius: 30px !important;
}

@media (max-width: 1650px) and (max-height: 900px) {
  .square-landing-1 {
    left: calc(-238vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -34% !important;
    top: -2% !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1600px) and (max-height: 900px) {
  .square-landing-1 {
    left: calc(-238vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -38% !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1557px) and (max-height: 900px) {
  .square-landing-1 {
    left: calc(-238vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -51% !important;
    top: -27% !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1500px) and (max-height: 867px) {
  .square-landing-1 {
    left: calc(-257vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -51% !important;
    top: -27% !important;
  }
  .export-docs-div {
    margin: 0px !important;
  }
}

@media (max-width: 1500px) and (max-height: 867px) {
  .square-landing-1 {
    left: calc(-257vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -51% !important;
    top: -27% !important;
  }
  .export-docs-div {
    margin: 0px !important;
  }
}

@media (max-width: 1361px) and (max-height: 867px) {
  .square-landing-1 {
    left: calc(-268vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 0px !important;
  }
}

@media (max-width: 1256px) and (max-height: 867px) {
  .square-landing-1 {
    left: calc(-278vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1200px) {
  .square-landing-1 {
    left: -1700px !important;
  }
}

@media (max-width: 1195px) {
  .square-landing-1 {
    left: calc(-292vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1176px) and (max-height: 867px) {
  .square-landing-1 {
    left: calc(-293vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1145px) {
  .square-landing-1 {
    left: calc(-295vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1143px) {
  .square-landing-1 {
    left: calc(-295vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1100px) {
  .square-landing-1 {
    left: calc(-300vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1065px) {
  .square-landing-1 {
    left: calc(-303vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1055px) {
  .square-landing-1 {
    left: calc(-305vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1365px) {
  .triangle {
    right: -55% !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .triangle {
    right: -55% !important;
    top: -4% !important;
  }
  .call-action {
    margin: 10% 0 21% !important;
  }
}

@media (max-width: 1200px) and (max-height: 760px) {
  .triangle {
    right: -63% !important;
  }
}

@media (max-width: 1115px) and (max-height: 867px) {
  .triangle {
    right: -95% !important;
  }
}

@media (max-width: 1024px) {
  .square-landing-1 {
    left: calc(-307vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -47% !important;
    border-left: 190vh solid transparent !important;
    border-bottom: 190vh solid #ff6600 !important;
  }
}

@media (max-width: 1024px) and (max-height: 867px) {
  .triangle {
    right: -95% !important;
    top: 4% !important;
  }
}

@media (max-width: 1480px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-234vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1390px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-249vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1366px) and (max-height: 657px) {
  .square-landing-1 {
    left: calc(-224vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -31% !important;
    top: -10% !important;
  }
  .export-docs-div {
    margin: 16% 0 !important;
  }
}

@media (max-width: 1310px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-254vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1285px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-259vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 1230px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-268vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1200px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-271vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1170px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-279vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -79% !important;
    top: -8% !important;
  }
}

@media (max-width: 1120px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-286vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -79% !important;
    top: -8% !important;
  }
}

@media (max-width: 1090px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-290vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -79% !important;
    top: -8% !important;
  }
}

@media (max-width: 1065px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-295vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -79% !important;
    top: -8% !important;
  }
}

@media (max-width: 1040px) and (max-height: 760px) {
  .square-landing-1 {
    left: calc(-298vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -79% !important;
    top: -8% !important;
  }
}

@media (max-width: 1024px) and (max-height: 657px) {
  .square-landing-1 {
    left: calc(-283vh * 1.4 / 2) !important;
  }
  .sociiPhone-div {
    padding-left: unset !important;
  }
  .triangle {
    right: -46% !important;
    top: 2% !important;
  }
}

@media (max-width: 1020px) {
  .square-landing-1 {
    left: calc(-304vh * 1.4 / 2) !important;
  }
}

@media (max-width: 1016px) and (max-height: 867px) {
  .triangle {
    right: -98% !important;
    top: 4% !important;
  }
}

@media (max-width: 1004px) {
  .square-landing-1 {
    left: calc(-309vh * 1.4 / 2) !important;
  }
}

@media (max-width: 979px) and (max-height: 867px) {
  .triangle {
    right: -106% !important;
    top: 4% !important;
  }
}

@media (max-width: 960px) {
  .triangle {
    right: -87% !important;
    border-left: 175vh solid transparent !important;
    border-bottom: 175vh solid #ff6600 !important;
  }
}

@media (max-width: 957px) and (max-height: 867px) {
  .triangle {
    right: -100% !important;
    top: 0px !important;
  }
}

@media (max-width: 932px) and (max-height: 867px) {
  .triangle {
    right: -105% !important;
    top: 0px !important;
  }
}

@media (max-width: 883px) and (max-height: 867px) {
  .triangle {
    right: -117% !important;
    top: 0px !important;
  }
}

@media (max-width: 843px) and (max-height: 867px) {
  .triangle {
    right: -100% !important;
    top: 0px !important;
  }
}

@media (max-width: 806px) and (max-height: 867px) {
  .triangle {
    right: -138% !important;
    top: 0px !important;
  }
}

@media (max-width: 775px) and (max-height: 867px) {
  .triangle {
    right: -148% !important;
    top: 0px !important;
  }
}

@media (max-width: 880px) {
  .square-landing-1 {
    top: -319px !important;
  }
}

@media (max-width: 850px) and (max-height: 568px) {
  .square-landing-1 {
    top: -319px !important;
    left: calc(-309vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 16% 0 !important;
  }
}

@media (max-width: 800px) and (max-height: 568px) {
  .square-landing-1 {
    top: -155px !important;
    left: calc(-295vh * 1.4 / 2) !important;
  }
  .export-docs-div {
    margin: 16% 0 !important;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .square-landing-1 {
    top: -155px !important;
    left: calc(-298vh * 1.4 / 2) !important;
  }
  .triangle {
    right: -129% !important;
  }
}

@media (max-height: 568px) {
  .generate-contract-div {
    margin: 10% 0 !important;
  }
}

@media (max-width: 420px) {
  .square-landing-1 {
    top: -248px !important;
    left: calc(-303vh * 1.4 / 2) !important;
  }
}

@media (max-width: 330px) {
  .square-landing-1 {
    top: -300px !important;
  }
}

@media (max-width: 756px) {
  .triangle {
    right: -136% !important;
    border-left: 175vh solid transparent !important;
    border-bottom: 175vh solid #ff6600 !important;
  }
}

@media (max-width: 756px) and (max-height: 867px) {
  .triangle {
    right: -155% !important;
    top: 0px !important;
  }
}

@media (max-width: 724px) {
  .triangle {
    right: -148% !important;
    border-left: 170vh solid transparent !important;
    border-bottom: 170vh solid #ff6600 !important;
  }
}

@media (max-width: 719px) and (max-height: 867px) {
  .triangle {
    right: -168% !important;
    top: 0px !important;
  }
}

@media (max-width: 682px) and (max-height: 867px) {
  .triangle {
    right: -182% !important;
    top: 0px !important;
  }
}

@media (max-width: 648px) and (max-height: 867px) {
  .triangle {
    right: -198% !important;
    top: 0px !important;
  }
}

@media (max-width: 617px) and (max-height: 867px) {
  .triangle {
    right: -212% !important;
    top: 0px !important;
  }
}

@media (max-width: 595px) and (max-height: 867px) {
  .triangle {
    right: -225% !important;
    top: 0px !important;
  }
}

@media (max-width: 584px) {
  .triangle {
    right: -168% !important;
  }
}

@media (max-width: 520px) {
  .triangle {
    right: -192% !important;
  }
}

@media (max-width: 456px) {
  .triangle {
    right: -225% !important;
  }
}

@media (max-width: 417px) {
  .triangle {
    right: -251% !important;
    top: -4% !important;
  }
}

@media (max-width: 417px) and (max-height: 568px) {
  .triangle {
    top: -24% !important;
  }
  .square-landing-1 {
    top: -221px !important;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .triangle {
    top: -10% !important;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .export-docs-div {
    margin-top: 22% !important;
  }
  .triangle {
    top: -21% !important;
  }
}

@media (max-width: 1200px) {
  .image-div {
    justify-content: flex-start !important;
  }
}

@media (max-width: 1052px) {
  .square2 {
    right: calc(-271vh * 1.4 / 2) !important;
  }
}

@media (max-width: 752px) {
  .square2 {
    right: calc(-291vh * 1.4 / 2) !important;
  }
}

@media (max-width: 600px) {
  .square2 {
    right: calc(-318vh * 1.4 / 2) !important;
  }
}

@media (max-width: 375px) {
  .square2 {
    right: calc(-328vh * 1.4 / 2) !important;
  }
}

/* ################################## */

@media (min-height: 684px) {
  .sphere1 {
    top: -139% !important;
  }
}

@media (min-height: 731px) {
  .sphere1 {
    top: -119% !important;
  }
}

@media (max-width: 1550px) and (max-height: 812px) {
  .sphere1 {
    right: -64% !important;
  }
}

@media (max-width: 1307px) and (max-height: 812px) {
  .sphere1 {
    right: -81% !important;
  }
}

@media (max-width: 1224px) and (max-height: 812px) {
  .sphere1 {
    right: -89% !important;
  }
}

@media (max-width: 1164px) and (max-height: 812px) {
  .sphere1 {
    right: -96% !important;
  }
}

@media (max-width: 1101px) and (max-height: 812px) {
  .sphere1 {
    right: -105% !important;
  }
}

@media (max-width: 1030px) and (max-height: 812px) {
  .sphere1 {
    right: -115% !important;
  }
}

@media (max-width: 981px) and (max-height: 812px) {
  .sphere1 {
    right: -124% !important;
  }
}

@media (min-height: 812px) {
  .sphere1 {
    top: -97% !important;
  }
}

@media (max-height: 749px) and (max-width: 1580px) {
  .sphere2 {
    left: -685px !important;
  }
  .signature-cellphone {
    justify-content: center !important;
    padding-left: 3rem !important;
  }
}

@media (max-height: 749px) and (max-width: 1489px) {
  .signature-cellphone {
    justify-content: center !important;
    padding-left: 3rem !important;
  }
}

@media (max-height: 749px) and (max-width: 1400px) {
  .sphere2 {
    left: -915px !important;
  }
  .signatures-div {
    padding-right: 4rem !important;
  }
  .signature-cellphone {
    justify-content: center !important;
    padding-left: 3rem !important;
  }
}

@media (max-height: 749px) and (max-width: 1300px) {
  .sphere2 {
    left: -915px !important;
  }
  .autentication-div {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .signatures-div {
    padding-right: 4rem !important;
  }
  .signature-cellphone {
    padding-right: 0 !important;
    justify-content: center !important;
  }
}

@media (max-height: 749px) and (max-width: 1243px) {
  .sphere2 {
    left: -915px !important;
  }

  .autentication-div {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .signatures-div {
    padding-right: 3rem !important;
  }
  .signature-cellphone {
    padding-left: 0 !important;
    justify-content: flex-end !important;
  }
}

@media (max-height: 749px) and (max-width: 1163px) {
  .sphere2 {
    left: -970px !important;
  }
  .autentication-section {
    padding-left: 5% !important;
  }
  .autentication-div {
    margin-left: 1rem !important;
    padding-left: 0 !important;
  }
  .signatures-div {
    padding-right: 3rem !important;
  }
  .signature-cellphone {
    padding-left: 0 !important;
    justify-content: flex-end !important;
  }
}

@media (max-height: 749px) and (max-width: 1100px) {
  .sphere2 {
    left: -1014px !important;
  }
  .autentication-section {
    padding-left: 5% !important;
  }
  .autentication-div {
    margin-left: 1rem !important;
    padding-left: 0 !important;
  }
  .signatures-div {
    margin-left: 0 !important;
    padding-right: 0 !important;
  }
  .signature-cellphone {
    padding-left: 0 !important;
    justify-content: flex-end !important;
  }
}

@media (max-height: 749px) and (max-width: 1054px) {
  .sphere2 {
    left: -1014px !important;
  }
  .autentication-section {
    padding-left: 5% !important;
  }
  .autentication-div {
    margin-left: 1rem !important;
    padding-left: 0 !important;
  }
  .signatures-div {
    margin-left: 1rem !important;
    padding-right: 0 !important;
  }
  .signature-section {
    margin-top: 14% !important;
  }
  .signature-cellphone {
    padding-left: 0 !important;
    justify-content: flex-end !important;
  }
}

@media (max-width: 1550px) and (max-height: 1020px) {
  .sphere1 {
    right: -62% !important;
  }
  .sphere2 {
    left: -712px !important;
  }
  .registerMark-sphere {
    left: -74% !important;
    top: -153% !important;
    right: unset !important;
  }
  .smallSphere1 {
    right: -20% !important;
  }
  .smallSphere2 {
    left: -20% !important;
  }
}

@media (max-width: 1550px) and (max-height: 920px) {
  .sphere1 {
    right: -62% !important;
  }
  .sphere2 {
    left: -712px !important;
  }
  .registerMark-sphere {
    left: -74% !important;
    /* top: -171% !important; */
  }
  .smallSphere1 {
    right: -20% !important;
  }
  .smallSphere2 {
    left: -20% !important;
  }
}

@media (max-width: 1550px) and (max-height: 850px) {
  .sphere1 {
    right: -62% !important;
  }
  .sphere2 {
    left: -712px !important;
  }
  .registerMark-sphere {
    left: -68% !important;
    top: -190% !important;
  }
  .smallSphere1 {
    right: -20% !important;
  }
  .smallSphere2 {
    left: -20% !important;
  }
}

@media (max-width: 1550px) and (max-height: 819px) {
  .sphere1 {
    right: -62% !important;
  }
  .sphere2 {
    left: -712px !important;
  }
  .registerMark-sphere {
    left: -71% !important;
    top: -202% !important;
  }
  .smallSphere1 {
    right: -20% !important;
  }
  .smallSphere2 {
    left: -20% !important;
  }
}

@media (max-width: 1550px) and (max-height: 757px) {
  .sphere1 {
    right: -62% !important;
  }
  .sphere2 {
    left: -712px !important;
  }
  .registerMark-sphere {
    left: -69% !important;
    top: -219% !important;
  }
  .smallSphere1 {
    right: -20% !important;
  }

  .smallSphere2 {
    left: -20% !important;
  }
}

@media (max-width: 1550px) and (max-height: 700px) {
  .sphere1 {
    right: -62% !important;
  }
  .sphere2 {
    left: -712px !important;
  }
  .registerMark-sphere {
    left: -83% !important;
    top: -219% !important;
  }
  .smallSphere1 {
    right: -20% !important;
  }

  .smallSphere2 {
    left: -20% !important;
  }
}

@media (max-width: 1190px) and (max-height: 679px) {
  .registerMark-sphere {
    right: 73% !important;
  }
}

/* REGISTER TRADEMARK PAGE */
@media (max-width: 1000px) and (max-height: 800px) {
  .registerMark-sphere {
    left: unset !important;
    right: 76% !important;
  }

  .smallSphere1 {
    right: -39% !important;
  }
  .smallSphere2 {
    left: -39% !important;
  }
  .images-grid {
    height: 58% !important;
  }
  .default-btn-grid {
    max-width: 210px !important;
  }
}

/* GALAXY S20 ULTRA */
@media (max-width: 412px) and (max-height: 915px) {
  .smallSphere2 {
    left: -105% !important;
  }
  .images-grid {
    height: 87% !important;
  }
  .registerMark-sphere {
    top: -110% !important;
    left: -189% !important;
  }
}

/* IPHONE 12 PRO */
@media (max-width: 390px) and (max-height: 844px) {
  .smallSphere2 {
    left: -105% !important;
  }
  .images-grid {
    height: 90% !important;
  }
  .registerMark-sphere {
    top: -94% !important;
  }
}
/*
@media (min-width: 600px) and (max-height: 844px) {
  .smallSphere1 {
    right: -63% !important;
  }
} */

/*  Galaxy s8 +  */
@media (max-width: 361px) and (max-height: 742px) {
  .callAction-title {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 1507px) and (max-height: 679px) {
  .sphere2 {
    left: -830px !important;
  }
}

@media (max-width: 1441px) and (max-height: 679px) {
  .sphere1 {
    top: -143% !important;
  }
}

/* Iphone SE */
@media (max-width: 376px) and (max-height: 668px) {
  .registerMark-sphere {
    top: -98% !important;
    right: 0 !important;
    left: -227% !important;
  }
  .description {
    font-size: 1.5rem !important;
  }
  .smallSphere1 {
    left: 91% !important;
  }
  .partners-brand {
    font-size: 1.5rem !important;
  }
  .smallSphere2 {
    left: -120% !important;
  }
  .grid-width {
    max-width: 80% !important;
  }
  .partners-grid {
    max-width: 80% !important;
  }
  .form-main-text {
    font-size: 1rem !important;
  }
  .images-grid {
    height: 92% !important;
  }
  .partners-grid {
    padding: 7px !important;
  }
  .firstGrid-valueCard {
    padding-bottom: 1em !important;
  }
  .callAction-title {
    font-size: 1.8rem !important;
  }
  .main-title {
    font-size: 1.85rem !important;
  }
  .div-handshaking-image {
    margin-top: 2em !important;
  }
  .grid-cellMobile {
    top: -6% !important;
  }
}

/* BlackBerry Z30 */
@media (max-width: 361px) and (max-height: 641px) {
  .main-title {
    font-size: 1.85rem !important;
  }
  .div-handshaking-image {
    margin-top: 2em !important;
  }
  .grid-cellMobile {
    top: -6% !important;
  }
}

@media (min-height: 900px) and (max-width: 1680px) {
  .registerMark-sphere {
    right: 54% !important;
  }
}

@media (max-height: 1200px) and (max-width: 1100px) {
  .registerMark-sphere {
    right: 44% !important;
  }
}

@media (max-height: 940px) and (max-width: 1100px) {
  .registerMark-sphere {
    right: 54% !important;
  }
}

@media (max-height: 850px) and (max-width: 1350px) {
  .registerMark-sphere {
    right: 63% !important;
    left: unset !important;
  }
  .smallSphere1 {
    right: -39% !important;
  }
}

@media (max-height: 657px) and (max-width: 958px) {
  .registerMark-sphere {
    right: 74% !important;
  }
}

/* @media (min-height: 700px) and (max-width: 1680px) {
  .registerMark-sphere {
    right: 61% !important;
  }
} */

@media (max-height: 679px) and (max-width: 1307px) {
  .sphere1 {
    right: -78% !important;
  }
}

@media (max-width: 1293px) and (max-height: 679px) {
  .sphere2 {
    left: -975px !important;
  }
}

@media (max-width: 1223px) and (max-height: 679px) {
  .sphere2 {
    left: -967px !important;
  }
  .signatures-div {
    padding-right: 4rem !important;
  }
}

@media (max-width: 1132px) and (max-height: 679px) {
  .sphere2 {
    left: -1001px !important;
  }
  .signatures-div {
    padding-right: 2rem !important;
  }
  .autentication-div {
    padding-left: 0 !important;
  }
}

@media (max-width: 1063px) and (max-height: 679px) {
  .sphere2 {
    left: -1039px !important;
  }
  .signatures-div {
    padding-right: 0 !important;
  }
  .autentication-section {
    padding-left: 5% !important;
  }
  .third-section {
    margin-left: 1rem !important;
  }
}

@media (max-width: 994px) and (max-height: 679px) {
  .sphere2 {
    left: -1055px !important;
  }
}

@media (max-height: 679px) and (max-width: 1224px) {
  .sphere1 {
    right: -86% !important;
  }
}

@media (max-height: 679px) and (max-width: 1167px) {
  .sphere1 {
    right: -93% !important;
  }
}

@media (max-height: 679px) and (max-width: 1101px) {
  .sphere1 {
    right: -101% !important;
  }
}

@media (max-height: 679px) and (max-width: 1055px) {
  .sphere1 {
    right: -108% !important;
  }
}

@media (max-height: 679px) and (max-width: 1006px) {
  .sphere1 {
    right: -115% !important;
  }
}

@media (max-height: 679px) and (max-width: 972px) {
  .sphere1 {
    right: -121% !important;
  }
}

@media (min-width: 1400px) and (max-height: 657px) {
  .sphere-img {
    margin-left: 8rem !important;
  }
}

@media (max-width: 1353px) and (max-height: 657px) {
  .sphere1 {
    right: -62% !important;
  }
}

@media (max-width: 1310px) and (max-height: 657px) {
  .sphere1 {
    right: -73% !important;
  }
  .sphere-img {
    margin-left: 5rem !important;
  }
}

@media (max-width: 1252px) and (max-height: 657px) {
  .sphere1 {
    right: -80% !important;
  }
  .sphere-img {
    margin-left: 6rem !important;
  }
}

@media (max-width: 1198px) and (max-height: 657px) {
  .sphere1 {
    right: -86% !important;
  }
  .sphere-img {
    margin-left: 5rem !important;
  }
}

@media (max-width: 1158px) and (max-height: 657px) {
  .sphere1 {
    right: -90% !important;
  }
}

@media (max-width: 1109px) and (max-height: 657px) {
  .sphere1 {
    right: -95% !important;
  }
}

@media (max-width: 1075px) and (max-height: 657px) {
  .sphere1 {
    right: -100% !important;
  }
}

@media (max-width: 1041px) and (max-height: 657px) {
  .sphere1 {
    right: -105% !important;
  }
}

@media (max-width: 1015px) and (max-height: 657px) {
  .sphere1 {
    right: -109% !important;
  }
}

@media (max-width: 995px) and (max-height: 657px) {
  .sphere1 {
    right: -113% !important;
  }
}

@media (max-width: 978px) and (max-height: 657px) {
  .sphere1 {
    right: -116% !important;
  }
}

@media (max-width: 414px) and (max-height: 736px) {
  .sphere1 {
    top: -26% !important;
  }
  .sphere2 {
    bottom: -912px !important;
  }
  .autentication-div {
    margin-left: 0 !important;
  }
  .signatures-div {
    margin: 0 !important;
  }
  .third-section {
    margin-left: 0 !important;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .sphere1 {
    top: -26% !important;
  }
  .sphere2 {
    bottom: -835px !important;
    left: -1050px !important;
  }
  .callAction-section {
    margin: 0 !important;
  }
  .negotiate-section {
    margin: 0 !important;
  }
  .signature-section {
    margin: 0 !important;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .sphere1 {
    top: -27% !important;
  }
  .sphere2 {
    bottom: -980px !important;
  }
  .autentication-div {
    margin: 0 !important;
  }
  .signatures-div {
    margin: 0 !important;
  }
  .third-section {
    margin-left: 0 !important;
  }
  .autentication-section {
    padding-left: 10% !important;
  }
  .negotiate-section {
    margin: 0 !important;
  }
  .callAction-section {
    margin: 47% 0 0 !important;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .sphere2 {
    bottom: -639px !important;
    width: 1150px !important;
    height: 1150px !important;
    left: -688px !important;
  }

  .autentication-section {
    padding-left: 10% !important;
  }
  .callAction-section {
    margin: 10% 0 0 !important;
  }
}

@media (min-width: 1599px) {
  .firstSecText {
    padding-right: 3rem !important;
  }
}

@media (min-width: 1096px) and (max-width: 1276px) {
  .firstSecText {
    padding-right: 2rem !important;
  }
}

@media (min-width: 600px) and (max-width: 833px) {
  .lawyer-circle1 {
    left: -65% !important;
  }
}

@keyframes start-rotate {
  100% {
    transform: rotate(360deg);
  }
}
